<template>
	<div class="editgiftdetails" v-loading="detailLoading">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>基本信息</span>
				</div>
				<div class="content">
					<el-form-item label="活动名称：" prop="Name">
						<!-- 已结束的禁止编辑 -->
						<el-input v-model="ruleForm.Name" disabled style="width: 220px;" placeholder="最多输入20个字" maxlength="20"></el-input>
					</el-form-item>

					<el-form-item label="活动时间：" required>
						<el-form-item prop="StartTime" class="el-form-date-pick">
							<el-date-picker disabled v-model="ruleForm.StartTime" type="datetime" placeholder="开始时间" :picker-options='maxOptions'>
							</el-date-picker>
						</el-form-item>
						<span style="margin:0 10px;">~</span>
						<el-form-item prop="EndTime" class="el-form-date-pick">
							<el-date-picker disabled v-model="ruleForm.EndTime" type="datetime" placeholder="结束时间" :picker-options='minOptions'>
							</el-date-picker>
						</el-form-item>
					</el-form-item>
				</div>
			</el-card>

			<el-card class="box-card" style="margin:15px 0 80px;">
				<div slot="header" class="clearfix">
					<span>活动信息</span>
				</div>

				<div class="content" style="width:1000px;">
					<el-form-item label="活动门槛：" required>
						<span>购买指定商品参加</span>
						<div>
							<el-input v-model="productSearchKey" placeholder="搜索已选商品名称、编码" clearable style="width:300px;"
							 @keyup.enter.native="handleFilterSelectes">
								<el-button slot="append" icon="el-icon-search" @click='handleFilterSelectes'></el-button>
							</el-input>
						</div>

						<el-table border :data="paginationProduct" style="width:1000px;margin-top:20px" v-loading="productLoading"
						 :row-key="productRowKeys">
							<el-table-column :key="5" label="商品" width="300px">
								<template slot-scope="scope">
									<div class="dialog-name-content">
										<img :src="imgUrl+scope.row.ImgUrl+'@!cut64'" />
										<div class="right">
											<div class="name">
												<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
											</div>
											<span>{{scope.row.State}}</span>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column :key="6" prop="ProductPrice" label="价格"></el-table-column>
							<el-table-column :key="7" prop="Stock" label="库存"></el-table-column>
						</el-table>
						<el-pagination v-if="proPage.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
						 @current-change="handleCurrentChange" :current-page="proPage.current" :page-sizes="[5,10, 20]" :page-size="proPage.size"
						 layout="total, sizes, prev, pager, next, jumper" :total="proPage.total">
						</el-pagination>

					</el-form-item>

					<el-form-item label="活动权益：" required>
						<!-- 抽奖活动表格 -->
						<el-table :data="GiftdetailData" style="width:1000px;margin:15px 0" v-loading="loading" ref="multipleTable"
						 :row-key="rowKeys">
							<el-table-column prop="Name" label="活动">
								<template slot-scope="scope">
									<div class="towLines">{{scope.row.Name}}</div>
								</template>
							</el-table-column>
							<el-table-column prop="RoomId" label="活动时间">
								<template slot-scope="scope">
									{{scope.row.StartTime}} 至
									{{scope.row.EndTime}}
								</template>
							</el-table-column>
						
						</el-table>
					</el-form-item>

					<el-form-item label="活动入口图片配置:" label-width="200px">
						<div class="filter-container">
							<div class="filter-item">
								<img v-if="ruleForm.ActivityEntranceImgUrl" :src="imgUrl+ruleForm.ActivityEntranceImgUrl" alt="" style="width:300px;height:40px;margin-right:10px;">
							</div>

							<div class="filter-item" style="color:#999">
								<div>图片请上传：</div>
								<div>1.建议尺寸长750px;宽100px</div>
								<div>2.大小请控制在1M以内</div>
								<div>3.图片在支付成功页展示，<span style="color:#409EFF;cursor: pointer;" @click="lookBig">点击查看示例图</span></div>
							</div>
						</div>
					</el-form-item>
				</div>

			</el-card>
		</el-form>

		<!--查看示例图  -->
		<el-dialog title="查看示例图" :visible.sync="lookBigimg" width="550px">
			<img :src="imgUrl+lookImgs" alt="" class="lookImgsWidth">
			<div style="text-align: center;margin: 40px 0 20px;">
				<el-button @click="lookBigimg=false" style="width:120px;margin-right:15px">关闭</el-button>
			</div>
		</el-dialog>

		<div class="bottom-save-btn">
			<el-button style="width:240px" @click="canselEdit">关闭</el-button>
		</div>

	</div>
</template>


<script>
	import {
		activityPayGiftSave,
		activityPayGiftInfo
	} from '@/api/goods'

	import config from '@/config/index'
	export default {
		data() {
			var checkName = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入活动名称'));
				} else if (!value.trim()) {
					return callback(new Error('请输入活动名称'));
				} else {
					return callback()
				}
			};
			return {
				loading: false,
				productFilter: '',
				lookBigimg: false,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				fileList: [],
				lookImgs: '',
				productSearchKey: '',
				productLoading: false,
				selectProDialog: false,
				selectactiveDialog: false,
				saveLoading: false,
				couponData: {},
				detailLoading: false,
				GiftdetailData: [],
				paginationProduct: [],
				ruleForm: {
					Name: '',
					StartTime: '',
					EndTime: '',
					ActivityEntranceImgUrl: '/image/20200519-957e7b38-d3dd-4d69-a0aa-480d353f1317.png',
					ProductList: []
				},
				rules: {
					Name: [{
						required: true,
						// message: '请输入活动名称',
						trigger: 'blur',
						validator: checkName
					}],
					StartTime: [{
						type: 'date',
						required: true,
						message: '请选择开始日期',
						trigger: 'blur'
					}],
					EndTime: [{
						type: 'date',
						required: true,
						message: '请选择结束日期',
						trigger: 'blur'
					}],
				},
				proPage: {
					current: 1,
					total: 0,
					size: 5
				},

			}
		},
		computed: {
			//日期选择器限制选择
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
		beforeMount() {
			this.getInformation()
		},
		methods: {
			// 初始化数据
			async getInformation() {
				try {
					this.detailLoading = true;
					if (this.$route.query.Id > 0) {
						let data = {
							Id: this.$route.query.Id
						}
						let result = await activityPayGiftInfo(data)
						this.ruleForm = result.Result
						this.ruleForm.ProductList = this.selectedFilterList = result.Result.ProductList
						this.GiftdetailData = []
						let obj = {
							Id: result.Result.ActivityWheelSurfId,
							Name: result.Result.ActivityWheelSurfName,
							StartTime: result.Result.ActivityWheelSurfStartTime,
							EndTime: result.Result.ActivityWheelSurfEndTime,
						}
						this.ruleForm.ActivityEntranceImgUrl = this.ruleForm.ActivityEntranceImgUrl ? this.ruleForm.ActivityEntranceImgUrl :
							'/image/20200519-957e7b38-d3dd-4d69-a0aa-480d353f1317.png'
						this.GiftdetailData.push(obj)
						this.paginationPro()
					}

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.detailLoading = false
				}

			},
			rowKeys(row) {
				return row.Id
			},
			productRowKeys(row) {
				return row.Id
			},

			handleSizeChange(val) {
				this.proPage.size = val;
				this.paginationPro()
			},
			handleCurrentChange(val) {
				this.proPage.current = val;
				this.paginationPro()
			},
			// 取消
			canselEdit() {
				this.$router.push({
					path:'/market/payGifts'					
				});
			},

			//获取选择的商品的列表
			getSelectList(data) {
				this.productSearchKey = ''
				this.ruleForm.ProductList = this.selectedFilterList = data
				this.selectProDialog = false
				this.handleFilterSelectes()
			},
			handleFilterSelectes() {
				this.proPage.current = 1
				this.selectedFilterList = []
				this.ruleForm.ProductList.filter(t => {
					if ((t.Name.indexOf(this.productSearchKey) != -1) || (t.ProductNo && (t.ProductNo.indexOf(this.productSearchKey) !=
							-1))) {
						this.selectedFilterList.push(t)
					}
				})
				this.paginationPro()
			},

			//商品分页
			paginationPro() {
				this.proPage.total = this.selectedFilterList.length;
				let start = (this.proPage.current - 1) * this.proPage.size
				let end = start + this.proPage.size
				let pagination = this.selectedFilterList.slice(start, end);
				this.paginationProduct = pagination
				this.$forceUpdate()
			},
			
			// 查看示例图
			lookBig() {
				this.lookBigimg = true
			},

		}
	}
</script>

<style lang="less" scoped>
	.editgiftdetails {
		padding: 10px;
		background: #fff;

		.el-form-date-pick {
			display: inline-block;
		}

		.towLines {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.lookImgsWidth {
			width: 500px;
		}

		.dialog-name-content {
			display: flex;
			flex-direction: row;

			img {
				width: 60px;
				height: 60px;
				object-fit: contain;
				margin-right: 10px;
			}

			.name {
				width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			span {
				color: #E51C23;
				font-size: 12px;
			}
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}

	}
</style>
